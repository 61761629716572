import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link', 'template', 'destination']
  static values = { wrapperSelector: { type: String, default: '.nested-fields' } }

  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onAddAssociationClick(event) {
    event.preventDefault()
    this.addAssociation(event.currentTarget)
  }

  onRemoveAssociationClick(event) {
    event.preventDefault()
    this.removeAssociation(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  addAssociation(target) {
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    if (this.destinationTarget.dataset.insertMethod == 'prepend') {
      this.destinationTarget.insertAdjacentHTML('afterbegin', content)
    } else {
      this.destinationTarget.insertAdjacentHTML('beforeend', content)
    }

    this.dispatch('association-added')
  }

  removeAssociation(target) {
    const wrapper = target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }

    this.dispatch('association-removed')
  }

  /* ----- GETTERS / SETTERS ----- */
}
