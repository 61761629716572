const serialize = form => {
  const params = []

  // Loop through each field in the form
  Array.from(form.elements).forEach(field => {
    // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
    if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return

    // If a multi-select, get all selections
    if (field.type === 'select-multiple') {
      for (let n = 0; n < field.options.length; n++) {
        if (!field.options[n].selected) continue
        params.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value))
      }
    } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) { // Convert field data to a query string
      params.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value))
    }
  })

  return params.join('&')
}

export { serialize }
