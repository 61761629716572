import 'datatables.net-bs4'
import { Controller } from '@hotwired/stimulus'

import { useControllerMixin } from 'scripts/mixins/controller'
import { language } from 'scripts/utils/application'
import { serialize } from 'scripts/utils/form'
import { FR } from 'scripts/admin/locales/datatable/fr'

export default class extends Controller {
  static targets = ['table', 'rowCheckbox', 'columnCheckbox', 'batchAction']
  static values = { options: Object }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    useControllerMixin(this)

    this.selectedRowIds = []
    this.allInputSelected = false

    this.selectors = {
      tableCallout: '.js-datatable-callout'
    }
  }

  connect() {
    this.setup()
  }

  /* ----- ACTION EVENTS ----- */

  onColumnCheckboxClick(event) {
    this.toggleAllRowCheckboxes()
  }

  onRowCheckboxClick(event) {
    this.toggleRowCheckbox(event.currentTarget)
  }

  onSelectAllListCheckboxesClick(event) {
    event.preventDefault()
    this.allInputSelected = true
    this.refreshAllListCheckboxes()
  }

  onUnselectAllListCheckboxesClick(event) {
    event.preventDefault()
    this.allInputSelected = false
    this.refreshAllListCheckboxes()
  }

  onFilterFormSubmit(event) {
    event.preventDefault()
    const params = serialize(event.currentTarget)
    this.updateTableSource(params)
  }

  onFilterFormResetClick(event) {
    this.updateTableSource()
  }

  /* ----- FUNCTIONS ----- */

  setup() {
    if (!this.element.querySelector('.dataTables_wrapper')) {
      this.$datatable = $(this.tableTarget).DataTable(this.options)
    }
  }

  updateTableSource(params) {
    const path = this.tableTarget.dataset.ajax.split('?')[0]
    const url = params ? path + '?' + params : path

    if (this.hasColumnCheckboxTarget) {
      this.columnCheckboxTarget.checked = false
    }

    this.selectedRowIds = []
    this.refreshTableCalloutContent()
    this.setBatchActionIds()
    this.$datatable.ajax.url(url).load()
  }

  refreshAllListCheckboxes() {
    if (this.hasColumnCheckboxTarget) {
      if (this.allInputSelected) {
        this.rowCheckboxTargets.forEach(rowCheckbox => {
          rowCheckbox.disabled = true
          rowCheckbox.checked = true
        })

        this.columnCheckboxTarget.disabled = true
        this.columnCheckboxTarget.checked = true
      } else {
        this.rowCheckboxTargets.forEach(rowCheckbox => {
          rowCheckbox.disabled = false
          rowCheckbox.checked = this.selectedRowIds.includes(parseInt(rowCheckbox.value))
        })

        this.columnCheckboxTarget.disabled = false
        this.columnCheckboxTarget.checked = this.rowCheckboxTargets.every(checkbox => checkbox.checked)
      }
    }

    this.refreshTableCalloutContent()
    this.setBatchActionIds()
  }

  toggleAllRowCheckboxes() {
    this.rowCheckboxTargets.forEach(rowCheckbox => {
      rowCheckbox.checked = this.columnCheckboxTarget.checked

      if (rowCheckbox.checked) {
        this.addSelectedRowId(rowCheckbox.value)
      } else {
        this.removeSelectedRowId(rowCheckbox.value)
      }
    })
  }

  toggleRowCheckbox(rowCheckbox) {
    if (rowCheckbox.checked) {
      this.columnCheckboxTarget.checked = this.rowCheckboxTargets.every(checkbox => checkbox.checked)
      this.addSelectedRowId(rowCheckbox.value)
    } else {
      this.removeSelectedRowId(rowCheckbox.value)
    }
  }

  addSelectedRowId(id) {
    this.selectedRowIds.push(parseInt(id))
    this.selectedRowIds = [...new Set(this.selectedRowIds)] // Remove duplicates
    this.selectedRowIds.sort()
    this.refreshTableCalloutContent()
    this.setBatchActionIds()
  }

  removeSelectedRowId(id) {
    this.selectedRowIds = this.selectedRowIds.filter(rowId => parseInt(rowId) !== parseInt(id))
    this.columnCheckboxTarget.checked = false // Uncheck column checkbox
    this.refreshTableCalloutContent()
    this.setBatchActionIds()
  }

  setBatchActionIds() {
    if (this.currentBatchActionController) {
      const allInputValue = this.allInputSelected ? 1 : 0
      this.currentBatchActionController.setAllInputValue(allInputValue)
      this.currentBatchActionController.setSelectionInputValue(this.selectedRowIds)
    }
  }

  refreshTableCalloutContent() {
    if (this.allInputSelected) {
      this.setTableCalloutContent(this.totalSelectionMessage)
    } else if (this.hasColumnCheckboxTarget && this.columnCheckboxTarget.checked && this.$datatable.page.info().pages > 1) {
      this.setTableCalloutContent(this.partialSelectionMessage)
    } else {
      this.setTableCalloutContent('')
    }
  }

  setTableCalloutContent(content) {
    this.element.querySelector(this.selectors.tableCallout).innerHTML = content
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {
      language: this.locale,
      searching: false,
      processing: false,
      serverSide: true,
      ajax: '',
      pagingType: 'full_numbers',
      lengthMenu: [25, 50, 100],
      order: [],
      dom: this.tableDom,
      scrollX: true,
      responsive: true,
      columnDefs: [],
      footerCallback: (row, data, start, end, display) => {
        if (row) {
          Array.from(row.querySelectorAll('.text-center')).forEach(cell => cell.classList.remove('text-center'))
        }
      },
      fnDrawCallback: (oSettings) => {
        this.refreshAllListCheckboxes()
      }
    }
  }

  get currentBatchActionTarget() {
    return [...this.batchActionTargets].pop()
  }

  get currentBatchActionController() {
    return this.currentBatchActionTarget ? this.getControllerForElement(this.currentBatchActionTarget, 'batch-action') : this.currentBatchActionTarget
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      fr: FR
    }
  }

  get partialSelectionMessage() {
    return this.locale.oBarPartialSelectMsg.replace('_TOTAL_', this.$datatable.page.info().recordsTotal)
  }

  get totalSelectionMessage() {
    return this.locale.oBarTotalSelectMsg.replace('_TOTAL_', this.$datatable.page.info().recordsTotal)
  }

  get tableDom() {
    return `
      <"row"<"col-12"<"callout callout-primary bg-primary dataTables_callout js-datatable-callout">>>
      <"row"<"col-lg-5 pb-2"i><"col-lg-7"p>>
      <"row"<"col-12"tr>>
      <"row"<"col-lg-5 pt-2"l><"col-lg-7"p>>
    `
  }
}
