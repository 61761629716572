import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onToggleRowClick(event) {
    event.preventDefault()

    const row = event.currentTarget.closest('tr').nextElementSibling

    if (row.classList.contains('hide')) {
      event.currentTarget.innerHTML = event.params.hideText
    } else {
      event.currentTarget.innerHTML = event.params.showText
    }

    row.classList.toggle('hide')
  }

  /* ----- FUNCTIONS ----- */

  /* ----- GETTERS / SETTERS ----- */
}
