import 'admin-lte/dist/img/AdminLTELogo.png'
import 'admin-lte/dist/img/user1-128x128.jpg'

import 'admin-lte'
import 'bootstrap'
import 'overlayscrollbars/js/jquery.overlayScrollbars.min'

$(document).ready(function() {
  $(document).on("click", "#user_departments_select_all", function(e) {
    e.preventDefault();

    $("#user_department_ids").find('option').prop("selected", true);
    $("#user_department_ids").trigger('change');

    return false;
  });

  $(document).on("click", "#user_departments_deselect_all", function(e) {
    e.preventDefault();

    $("#user_department_ids").find('option').prop("selected", false);
    $("#user_department_ids").trigger('change');

    return false;
  });
});
