import tippy from 'tippy.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { options: Object }

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.setup()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  setup() {
    this.tooltip = tippy(this.element, this.options)
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {}
  }
}
