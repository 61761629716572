import _ from 'lodash'

const language = document.documentElement.lang

const parseDOM = (data, type = 'text/html') => new DOMParser().parseFromString(data, type)

const serializeDOM = data => new XMLSerializer().serializeToString(data)

export { language, parseDOM, serializeDOM }

// change lodash delimiter to {{=}}
_.templateSettings = {
    interpolate: /\{\{\=(.+?)\}\}/g,
    evaluate: /\{\{(.+?)\}\}/g
};

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
