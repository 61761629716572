import { Notyf } from 'notyf'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { params: Object }

  /* ----- LIFECYCLE CALLBACKS ----- */

  initialize() {
    this.notification = new Notyf(this.options)
  }

  connect() {
    this.open()
  }

  /* ----- ACTION EVENTS ----- */

  /* ----- FUNCTIONS ----- */

  open() {
    this.notification.open(this.paramsValue)
  }

  /* ----- GETTERS / SETTERS ----- */

  get options() {
    return {
      duration: 6000,
      position: {
        x: 'right',
        y: 'top',
      },
      types: [
        {
          type: 'alert',
          className: 'bg-danger',
          icon: {
            className: 'fal fa-exclamation-triangle',
            tagName: 'i'
          }
        },
        {
          type: 'error',
          className: 'bg-danger',
          icon: {
            className: 'fal fa-exclamation-triangle',
            tagName: 'i'
          }
        },
        {
          type: 'info',
          className: 'bg-info',
          icon: {
            className: 'fal fa-info-circle',
            tagName: 'i'
          }
        },
        {
          type: 'notice',
          className: 'bg-success',
          icon: {
            className: 'fal fa-check-circle',
            tagName: 'i'
          }
        },
        {
          type: 'success',
          className: 'bg-success',
          icon: {
            className: 'fal fa-check-circle',
            tagName: 'i'
          }
        },
        {
          type: 'warning',
          className: 'bg-warning',
          icon: {
            className: 'fal fa-exclamation-circle',
            tagName: 'i'
          }
        }
      ]
    }
  }
}
