import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['item']
  static classes = ['hidden']

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none'
  }

  toggle(event) {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
  }

  show(event) {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
  }

  hide(event) {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
  }
}
