import consumer from "../../channels/consumer"
import { flashNotice } from "../utils/flash"

consumer.subscriptions.create({channel: "UserNotificationsChannel"}, {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('connected to notifications channel')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('received notification')
    flashNotice(data.content)
  }
});
