import Rails from '@rails/ujs'
import Swal from 'sweetalert2'

import { language } from 'scripts/utils/application'
import { FR } from 'scripts/admin/locales/confirm/fr'

class Confirm {
  init() {
    const allowAction = el => {
      const target = el.dataset.confirm ? el : el.closest('[data-confirm]')
      this.show(target)
      return false
    }

    Rails.delegate(document, '[data-confirm]', 'click', e => {
      if (!allowAction(e.target)) Rails.stopEverything(e)
    })
  }

  async show(target) {
    const result = await Swal.fire({
      text: target.dataset.confirm,
      showCancelButton: true,
      cancelButtonColor: '#dc3545',
      confirmButtonColor: '#28a745',
      cancelButtonText: this.locale.cancelButtonText,
      confirmButtonText: this.locale.confirmButtonText,
    })

    if (result.isConfirmed) {
      target.removeAttribute('data-confirm')
      target.click()
      target.dataset.confirm = true
    }
  }

  get locale() {
    return this.locales[language]
  }

  get locales() {
    return {
      fr: FR
    }
  }
}

const instance = new Confirm()
Object.freeze(instance)

export default instance
